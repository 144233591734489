import * as React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle"
import { Instagram } from "@styled-icons/boxicons-logos/Instagram"

import bg from "../images/bg-dark.png"

const FooterWrapper = styled.footer`
  background: url(${bg}) no-repeat center center;
  background-size: cover;
  padding: 50px 0 20px;
  margin: 75px auto 0;

  h4 {
    font-size: 1.25rem;
  }

  a {
    margin: 5px;
  }

  svg {
    fill: #ccc;
  }
`

const FooterContainer = styled(Container)`
  margin-bottom: 50px;
`

const StyledCol = styled(Col)`
  margin-bottom: 25px;
`

const FooterInfo = styled(Container)`
  text-align: center;

  hr {
    border-bottom: 1px solid #ccc;
    margin-bottom: 40px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  a {
    display: inline-block;
    color: #ffffff;
  }
`

const Footer = () => (
  <FooterWrapper id="contato">
    <FooterContainer>
      <Row>
        <StyledCol sm="4" xs="12">
          <h4>Telefone / WhatsApp</h4>

          <p>(19) 99593.8158</p>

          <p>
            <a href="https://www.facebook.com/ConecTimme">
              <FacebookCircle size="30" />
            </a>

            <a href="https://www.instagram.com/conectimme/">
              <Instagram size="30" />
            </a>
          </p>
        </StyledCol>

        <StyledCol sm="4" xs="12">
          <h4>Email</h4>

          <p>contato@conectimme.com.br</p>

          <h4>CNPJ:</h4>
          <p>45.271.658/0001-76</p>
        </StyledCol>

        <StyledCol sm="4" xs="12">
          <h4>Endereço</h4>

          <p>
            R. Sebastião Nicolau Píres, 108 - Parque Industrial e Empresarial
            Catarina Biazio Ometto, Iracemápolis - SP, CEP: 13498-220
          </p>
        </StyledCol>
      </Row>
    </FooterContainer>

    <FooterInfo>
      <hr />
      <div>
        <p>© ConecTimme {new Date().getFullYear()}</p>
        <p>
          Desenvolvimento{" "}
          <a href="https://mzndigital.com" target="_blank" rel="noreferrer">
            MZN Digital
          </a>
        </p>
      </div>
    </FooterInfo>
  </FooterWrapper>
)

export default Footer
