import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Navbar, Nav, Container } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp"

const StyledNavbar = styled(Navbar)`
  background-color: #222222;
  /* box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 4%); */
  border-bottom: 1px solid #444;

  padding-top: 15px !important;
  padding-bottom: 15px !important;

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-brand {
    width: 225px;
  }

  @media (max-width: 330px) {
    .navbar-brand {
      width: 175px;
    }
  }
`

const StyledNav = styled(Nav)`
  font-size: 1.15rem;

  a {
    font-weight: 600;
    color: #ffffff !important;
  }

  a:hover,
  a:focus {
    color: rgba(255, 255, 255, 0.6) !important;
  }
`

const StyledNavBtn = styled(Nav)`
  a {
    background-color: #0c59db;
    border: 2px solid #0c59db;
    border-radius: 3px;
    padding: 10px 20px !important;
    color: #ffffff !important;
    text-align: center;
    transition: 0.3s;

    svg {
      fill: #ffffff;
      transition: 0.3s;
    }
  }

  a:hover {
    background-color: transparent;
    color: #0c59db !important;

    svg {
      fill: #0c59db;
    }
  }
`

const Header = ({ siteTitle }) => (
  <StyledNavbar expand="lg" className="fixed-top navbar-dark">
    <Container>
      <Navbar.Brand as={Link} to="/">
        <StaticImage
          src="../images/logo.png"
          height={50}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt={siteTitle}
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <StyledNav>
          <Nav.Link className="mx-3" as={Link} to="/">
            Início
          </Nav.Link>
          <Nav.Link className="mx-3" as={Link} to="#servicos">
            Serviços
          </Nav.Link>
          <Nav.Link className="mx-3" as={Link} to="#produtos">
            Produtos
          </Nav.Link>
          <Nav.Link className="mx-3" as={Link} to="#contato">
            Contato
          </Nav.Link>
        </StyledNav>

        <StyledNavBtn>
          <Nav.Link href="https://wa.me/5519995938158">
            WhatsApp <Whatsapp className="mx-1" size={23} />
          </Nav.Link>
        </StyledNavBtn>
      </Navbar.Collapse>
    </Container>
  </StyledNavbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
